import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import ArdsleyTitle from "../components/ArdsleyTitle";

export const PricingPageTemplate = ({ image, content, title }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <ArdsleyTitle text={title} />
              <div className="columns">
                <div className="column">
                  <HTMLContent content={content} />
                </div>
                <div className="column">
                  <img
                    width="400px"
                    src={
                      image.childImageSharp
                        ? image.childImageSharp.fluid.src
                        : image
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PricingPage = ({ data }) => {
  console.log("DATA", data);

  const {
    frontmatter: { image, title },
    html,
  } = data.markdownRemark;

  return (
    <Layout>
      <PricingPageTemplate image={image} content={html} title={title} />
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.object,
};

export default PricingPage;

export const pricingPageQuery = graphql`
  query pricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
